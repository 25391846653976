<template>
  <b-container class="mb-7">
    <h1 class="title">Users</h1>
    <UserList :includeAllOrganizations="false" />
  </b-container>
</template>

<script>
import UserList from '@/components/UserList';

export default {
  components: {
    UserList
  }
};
</script>

<style scoped>
.container {
  text-align: left;
  margin-left: 115px;
  max-width: 90%;
}
.user-table {
  margin-top: 2rem;
}
</style>
